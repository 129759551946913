import gql from "graphql-tag";

import {
    COMPANY_SHOPS_BILLINGINFO_ATTRIBUTES,
    SHOP_OVERRIDE_ATTRIBUTES,
    SHOP_ATTRIBUTES,
    ACTIVE_HOUR_ATTRIBUTES,
    CONTACT_INFORMATION_ATTRIBUTES,
    ONLINE_SHOP_SETTINGS_ATTRIBUTES,
    CATERING_SETTINGS_ATTRIBUTES,
    HOME_DELIVERY_SETTINGS_ATTRIBUTES,
    EXPRESS_POS_SETTINGS_ATTRIBUTES
} from "../fragments";

export const GET_PUBLIC_ID_AND_SHOP_NAME = gql`
    query getAllShops {
        getAllShops {
            publicId
            name
        }
    }
`;

export const GET_SHOP_BY_PUBLIC_IDS = gql`
    query getShopByPublicIds($publicShopIds: [String]) {
        getShopByPublicIds(publicShopIds: $publicShopIds) {
            publicId
            name
            desc
            companyId
            contactInformation {
                addressLine
                postCode
                city
                country
                phoneNumber
                email
            }
            imageUrl
            bannerUrl
            activeHours {
                dayOfWeek
                startingHour
                stoppingHour
            }
            settings {
                onlineSettings {
                    closeOnlineOrders
                    customClosedMessage
                }
            }
        }
    }
`;

export const GET_ONLINE_EXPRESS_STATUSES = gql`
    query getOnlineExpressStatusesQuery($shopId: String, $companyId: String) {
        getOnlineExpressStatuses(shopId: $shopId, companyId: $companyId) {
            companyUpdateChangeNumber
            shopUpdateChangeNumber
            webFrontendVersion
        }
    }
`;

export const GET_SHOP_CUSTOMER_DETAILS = gql`
    query getShopCustomerDetails {
        getShopCustomerDetails {
            shopName
            shopId
            ip
            lastSeenVersion
            lastUpdate
            frontendApplication
        }
    }
`;

export const GET_SHOP_ONLINE_CUSTOM_FEE = gql`
    query getShopOnlineCustomFee($shopId: String) {
        getShopOnlineCustomFee(shopId: $shopId) {
            onlineRates {
                rate
                enabled
                validUntil
                discountName
                webOrderType
            }
        }
    }
`;

export const GET_SHOP_INVOICE_CORRECTIONS = gql`
    query getInvoiceCorrectionsForShop($shopId: String) {
        getInvoiceCorrectionsForShop(shopId: $shopId) {
            id
            name
            amount
            incomingVat {
                vatRate
                amount
            }
            account
            vatAccount
            invoiceReferenceId
        }
    }
`;

export const GET_SHOP_BILLING_INFO = gql`
    query getShopBillingInfo($shopId: String) {
        getShopBillingInfo(shopId: $shopId) {
            billingAddressLine
            billingPhoneNo
            billingPostCode
            billingCity
            organisationNumber
            organisationName
            billingEmail
            invoiceEmail
            paymentAccount {
                paymentAccountType
                accountNumber
            }
        }
    }
`;

export const GET_SHOP_SETTINGS = gql`
    query getShopSettings($shopId: String!) {
        getShopSettings(shopId: $shopId) {
            allowedPaymentMethods
            refProductCategoryIdsToExcludeInKDS
            refBundleIdsToExcludeInKDS
            allowedNumberOfOrdersInKDSBatch
            showOnKDSWithinMinutesBeforePickup
            enableKitchenPrinterOnline
            enableKitchenPrinterPOS
            enableDoneCookingStatus
            enableDonePreppingStatus
            enablePickUpScreenQrOnReceipt
            enablePickupTabInOrderDisplay
            enableKitchenDisplay
            enablePunchClock
            updateChangeNumber
            predefinedComments
            allowCommentsInExpressPOS
            lockableParts
            lockableAccessCode
            pickupSettings {
                hiddenProductsOnline
                hiddenProductsThirdParty
                hiddenProductsPos
            }
            onlineSettings {
                alwaysAllowPostponePickupTime
                closeOnlineOrders
                customClosedMessage
                customOpenMessage
                commentToClient
                disableShopComments
                enableEatHere
                enableOnlineOrders
                pauseOnlineOrders
                onlineOrderPickupTimes
                onlineOrderPickupTimesPostpone
                eatingOptions
                rushHour
                rushHourWaitMinutes
                mandatoryCommentPlaceholder
                enableMandatoryComment
                qrQuickServe {
                    qrPickupOptionTableId
                    qrQuickServeMessage
                    enableExtraFieldOnlyForQuickServe
                }
            }
            cateringSettings {
                timeToConfirm
                enableCatering
                emailToConfirm
                noticeHours
                thirdPartyDeliveryType
                minOrderDeliveryAmount
                minOrderPickupAmount
            }
            homeDeliverySettings {
                enableHomeDelivery
                pauseHomeDelivery
                minAmountFreeDelivery
                minOrderTotalAmount
                postalCodePlaceholder
                thirdPartyDelivery {
                    thirdPartyDeliveryType
                }
            }
        }
    }
`;

export const GET_SHOP_SEND_SMS_NOTICE_FOR_PICKUP = gql`
    query getShopSendSmsNoticeForPickup($shopId: String!) {
        getShopOnlineSettings(shopId: $shopId) {
            sendSmsNoticeForPickup
        }
    }
`;

export const GET_SHOP_ONLINE_SETTINGS = gql`
    query getPublicShopById($publicId: String!) {
        getPublicShopById(publicId: $publicId) {
            settings {
                onlineSettings {
                    alwaysAllowPostponePickupTime
                    commentPlaceholder
                    commentToClient
                    disableShopComments
                    enableEatHere
                    enableOnlineOrders
                    pauseOnlineOrders
                    useHomePage
                    closeOnlineOrders
                    customClosedMessage
                    customOpenMessage
                    restaurantOffline
                    alcoholicBeverages
                    rushHour
                    rushHourWaitMinutes
                    sendSmsNoticeForPickup
                    onlineControlUnitPublicPosId
                    qrOnlineHideTips
                    disableTableService
                    defaultTip
                    mandatoryCommentPlaceholder
                    enableMandatoryComment
                    expressOnlineTextColor
                    qrQuickServe {
                        qrPickupOptionTableId
                        qrQuickServeMessage
                        enableExtraFieldOnlyForQuickServe
                    }
                }
                homeDeliverySettings {
                    enableHomeDelivery
                    pauseHomeDelivery
                    minAmountFreeDelivery
                    minOrderTotalAmount
                    postalCodePlaceholder
                    thirdPartyDelivery {
                        thirdPartyDeliveryType
                    }
                }
                cateringSettings {
                    noticeHours
                    noPickupHours
                    minOrderPickupAmount
                    minOrderDeliveryAmount
                }
            }
        }
    }
`;

export const GET_SHOP_BY_URL = gql`
    query getShopByUrl($url: String) {
        getShopByUrl(url: $url) {
            name
            publicId
        }
    }
`;

export const GET_COMPANY_SHOPS_ID_AND_NAME = gql`
    query getCompanyShops($companyId: String!) {
        getCompanyShops(companyId: $companyId) {
            id
            name
            companyId
            thirdPartyDeliveryServices {
                thirdPartyDeliveryType
            }
        }
    }
`;

export const GET_COMPANY_SHOPS = gql`
    query getCompanyShops($companyId: String!) {
        getCompanyShops(companyId: $companyId) {
            id
            publicId
            name
            desc
            imageUrl
            bannerUrl
            menuIds
            activeHours {
                dayOfWeek
                startingHour
                stoppingHour
            }
            organisationNumber
            companyId
            contactInformation {
                addressLine
                city
                country
                postCode
                phoneNumber
            }
            thirdPartyDeliveryServices {
                thirdPartyDeliveryType
                enableService
            }
            billingInfo {
                ... on BillingInfo {
                    ...shopBillingInfoResults
                }
            }
            settings {
                allowedPaymentMethods
                showOnKDSWithinMinutesBeforePickup
                refProductCategoryIdsToExcludeInKDS
                refBundleIdsToExcludeInKDS
                allowedNumberOfOrdersInKDSBatch
                dailyRoutineTime
                enableDoneCookingStatus
                enableDonePreppingStatus
                enablePickupTabInOrderDisplay
                enableKitchenDisplay
                enableKitchenPrinterOnline
                enableKitchenPrinterPOS
                enablePickUpScreenQrOnReceipt
                enablePunchClock
                updateChangeNumber
                predefinedComments
                enableSIEFileGeneration
                lockableParts
                lockableAccessCode
                enableStaffManagementService
                allowCommentsInExpressPOS
                enableAutoAcceptThirdPartyOrders
                predefinedThirdPartyCompletionTime
                expressPOSSettings {
                    ... on ExpressPOSSettings {
                        ...ExpressPOSSettingsResults
                    }
                }
                onlineSettings {
                    alwaysAllowPostponePickupTime
                    eatingOptions
                    commentToClient
                    disableShopComments
                    enableEatHere
                    enableOnlineOrders
                    onlineMenuIds
                    expressOnlineMenuIds
                    expressOnlineTextColor
                    expressOnlineOrderConfirmation
                    closeOnlineOrders
                    customClosedMessage
                    customOpenMessage
                    restaurantOnlineOrderUrl
                    mandatoryCommentPlaceholder
                    enableMandatoryComment
                    qrQuickServe {
                        qrPickupOptionTableId
                        qrQuickServeMessage
                        enableExtraFieldOnlyForQuickServe
                    }
                }
                homeDeliverySettings {
                    enableHomeDelivery
                }
                cateringSettings {
                    enableCatering
                    noPickupHours
                }
                thirdPartyAccountingSettings {
                    accountAuthenticationId
                    accessToken
                    refreshToken
                }
                staffManagementSystem {
                    systemName
                    computerName
                    apiKey
                    lastExportDate
                    managementType
                    guid
                }
                topicId
            }
            umbrellaShop
            qoplaCommentLogs {
                username
                addedAt
                qoplaComment
            }
        }
    }
    ${COMPANY_SHOPS_BILLINGINFO_ATTRIBUTES}
    ${EXPRESS_POS_SETTINGS_ATTRIBUTES}
`;

export const GET_PUBLIC_SHOPS_INFO_BY_COMPANY_ID = gql`
    query getShopsPublicInfoByCompanyId($companyId: String!) {
        getShopsPublicInfoByCompanyId(companyId: $companyId) {
            id
            publicId
            name
        }
    }
`;

export const GET_SHOPS_BY_NAME = gql`
    query getShopByName($searchString: String!) {
        getShopsByName(searchString: $searchString) {
            id
            publicId
            name
            companyId
            desc
            companyId
            imageUrl
            contactInformation {
                addressLine
                postCode
                city
                country
                phoneNumber
            }
            organisationNumber
            activeHours {
                dayOfWeek
                startingHour
                stoppingHour
                date
                desc
                closed
            }
            menuIds
        }
    }
`;

/**
 * @deprecated Please use GET_SHOP_BY_PUBLIC_ID, it uses fragments and this does NOT return a `PublicShop`
 */
export const GET_PUBLIC_SHOP_BY_ID = gql`
    query getPublicShopById($publicId: String!) {
        getPublicShopById(publicId: $publicId) {
            id
            publicId
            name
            desc
            companyId
            contactInformation {
                addressLine
                postCode
                city
                country
                phoneNumber
                email
            }
            imageUrl
            bannerUrl
            organisationNumber
            activeHours {
                dayOfWeek
                startingHour
                stoppingHour
            }
            menuIds
            settings {
                onlineSettings {
                    alwaysAllowPostponePickupTime
                    commentPlaceholder
                    qrEatingOptions
                    instagram
                    facebook
                    onlyAsap
                    eatingOptions
                    commentToClient
                    disableShopComments
                    enableOnlineOrders
                    sendSmsNoticeForPickup
                    expressOnlineOrderConfirmation
                    onlineMenuIds
                    expressOnlineMenuIds
                    expressOnlineTextColor
                    pauseOnlineOrders
                    useHomePage
                    url
                    closeOnlineOrders
                    customClosedMessage
                    customOpenMessage
                    onlineOrderPickupTimesPostpone
                    productCommentsEnabled
                    restaurantOffline
                    enableExpressOnline
                    rushHour
                    rushHourWaitMinutes
                    onlineControlUnitPublicPosId
                    mandatoryCommentPlaceholder
                    enableMandatoryComment
                    qrQuickServe {
                        qrPickupOptionTableId
                        qrQuickServeMessage
                        enableExtraFieldOnlyForQuickServe
                    }
                }
                shopTables {
                    id
                    name
                    posId
                }
                homeDeliverySettings {
                    pauseHomeDelivery
                    minAmountFreeDelivery
                    minOrderTotalAmount
                    postalCodePlaceholder
                    thirdPartyDelivery {
                        thirdPartyDeliveryType
                    }
                }
                cateringSettings {
                    thirdPartyDeliveryType
                    acceptInvoicePayment
                    noticeHours
                    minOrderDeliveryAmount
                    minOrderPickupAmount
                    noPickupHours
                    timeToConfirm
                    lastOrderDay {
                        dayOfWeek
                        stoppingHour
                        date
                    }
                    customCateringDates {
                        message
                        days
                    }
                    customSpecificCateringDates {
                        message
                        days
                    }
                    deliveryAreas {
                        enabled
                    }
                }
                showOnKDSWithinMinutesBeforePickup
            }
        }
    }
`;

export const GET_SHOP_BY_ID_AND_COMPANY_ID = gql`
    query getShopByIdAndCompanyId($shopId: String!, $companyId: String!) {
        getShopByIdAndCompanyId(shopId: $shopId, companyId: $companyId) {
            ... on Shop {
                ...shopResults
            }
        }
    }
    ${SHOP_ATTRIBUTES}
`;

export const GET_CATERING_SETTINGS_BY_SHOP_ID = gql`
    query getCateringSettingsByShopIdQuery($shopId: String!) {
        getShopById(shopId: $shopId) {
            settings {
                cateringSettings {
                    timeToConfirm
                    lastOrderDay {
                        dayOfWeek
                        stoppingHour
                        date
                    }
                    enableCatering
                    acceptInvoicePayment
                    emailToConfirm
                    noticeHours
                    thirdPartyDeliveryType
                    minOrderDeliveryAmount
                    minOrderPickupAmount
                    deliveryMessageToCustomer
                    pickupMessageToCustomer
                    noPickupHours
                    customCateringDates {
                        message
                        days
                    }
                    customSpecificCateringDates {
                        message
                        days
                    }
                    deliveryAreas {
                        name
                        deliveryFee
                        enabled
                        postalCodes
                        allowPickup
                        pickupAddress
                        customDates {
                            message
                            days
                        }
                        postalCodeRanges {
                            rangeStart
                            rangeEnd
                        }
                    }
                }
            }
        }
    }
`;

export const GET_SHOP_BY_PUBLIC_ID = gql`
    query getShopByPublicId($publicId: String!) {
        getShopByPublicId(publicId: $publicId) {
            id
            publicId
            name
            desc
            companyId
            imageUrl
            bannerUrl
            organisationNumber
            menuIds
            activeHours {
                closed
                date
                dayOfWeek
                desc
                startingHour
                stoppingHour
            }
            contactInformation {
                ... on ContactInformation {
                    ...contactInformationResults
                }
            }
            settings {
                shopTables {
                    id
                    name
                    tableId
                    posId
                }
                showOnKDSWithinMinutesBeforePickup
                partnerPromotionId
                onlineSettings {
                    ... on ShopOnlineSettings {
                        ...onlineSettingsResults
                    }
                }
                homeDeliverySettings {
                    ... on ShopHomeDeliverySettings {
                        ...homeDeliverySettingsResults
                    }
                }
                cateringSettings {
                    ... on ShopCateringSettings {
                        ...cateringSettingsResults
                    }
                }
            }
        }
    }
    ${ONLINE_SHOP_SETTINGS_ATTRIBUTES}
    ${HOME_DELIVERY_SETTINGS_ATTRIBUTES}
    ${CATERING_SETTINGS_ATTRIBUTES}
    ${CONTACT_INFORMATION_ATTRIBUTES}
`;

export const GET_PARTIAL_SHOP_BY_PUBLIC_ID = gql`
    query getShopByPublicId($publicId: String!) {
        getShopByPublicId(publicId: $publicId) {
            id
            publicId
            name
            companyId
            imageUrl
            settings {
                updateChangeNumber
                enableKitchenDisplay
                pickupSettings {
                    hiddenProductsOnline
                    hiddenProductsThirdParty
                    hiddenProductsPos
                }
            }
        }
    }
`;

export const GET_SHOP_BY_ID = gql`
    query getShopById($shopId: String!) {
        getShopById(shopId: $shopId) {
            ... on Shop {
                ...shopResults
            }
        }
    }
    ${SHOP_ATTRIBUTES}
`;

export const GET_SHOP_OVERRIDE = gql`
    query getShopOverride($shopId: String!) {
        getShopOverride(shopId: $shopId) {
            ... on ShopOverride {
                ...shopOverrideResults
            }
        }
    }
    ${SHOP_OVERRIDE_ATTRIBUTES}
`;

export const GET_SHOP_INVENTORY = gql`
    query getShopOverrideQuery($shopId: String!) {
        getShopOverride(shopId: $shopId) {
            id
            outOfStockProductIds
            outOfStockAddonNames
            inventoryStock {
                refProductId
                quantity
            }
        }
    }
`;

export const GET_PUBLIC_SHOP_INFOS_BY_IDS = gql`
    query getPublicShopInfosByIds($shopIds: [String]!) {
        getPublicShopInfosByIds(shopIds: $shopIds) {
            id
            name
            publicId
            imageUrl
        }
    }
`;
export const GET_SHOP_HAS_SIE_EXPORT_ENABLED = gql`
    query getShopHasSIEExportEnabled($shopId: String) {
        getShopHasSIEExportEnabled(shopId: $shopId)
    }
`;

export const PRODUCT_EXISTS_IN_SIE_ACCOUNT = gql`
    query productExistsInSIEAccount($companyId: String, $refCategoryId: String, $refProductId: String) {
        productExistsInSIEAccount(companyId: $companyId, refCategoryId: $refCategoryId, refProductId: $refProductId)
    }
`;

export const GET_POSTAL_CODES_WITHIN_RADIUS = gql`
    query getPostalCodesWithinRadius($postalNumber: String!, $radius: Float) {
        getPostalCodesWithinRadius(postalNumber: $postalNumber, radius: $radius)
    }
`;

export const SEARCH_DELIVERY_LOCATIONS = gql`
    query searchDeliveryLocations($search: String!) {
        searchDeliveryLocations(search: $search) {
            street
            city
            country
            postalCode
            address
            latLng {
                lat
                lng
            }
        }
    }
`;

export const GET_DELIVERY_INFORMATION = gql`
    query getDeliveryInformationQuery($shopId: String!, $postalCode: String!, $isCateringDelivery: Boolean) {
        getDeliveryInformation(shopId: $shopId, postalCode: $postalCode, isCateringDelivery: $isCateringDelivery) {
            deliveryFee
            pickupAddress
            allowPickup
            customCateringDates {
                days
                message
            }
        }
    }
`;

export const VALIDATE_DELIVERY_ADDRESS = gql`
    query validateDeliveryAddress($shopId: String!, $deliveryLocation: DeliveryLocationInput!) {
        validateDeliveryAddress(shopId: $shopId, deliveryLocation: $deliveryLocation)
    }
`;

export const GET_SUPER_ADMIN_POS_SHOPS = gql`
    query getShopsWithPOS {
        getShopsWithPOS {
            name
            id
        }
    }
`;

export const GET_SHOP_SETTINGS_FOR_POS = gql`
    query getShopSettings($shopId: String!) {
        getShopSettings(shopId: $shopId) {
            enablePunchClock
            predefinedComments
            allowedPaymentMethods
            lockableParts
            lockableAccessCode
            enablePickUpScreenQrOnReceipt
            allowCommentsInExpressPOS
            expressPOSSettings {
                expressPrimary
                expressSecondary
                advertisingImageUrl
                enableDiscounts
            }
        }
    }
`;

export const GET_SHOPS_BY_SEARCH = gql`
    query getShopsFromSearch($search: String!) {
        getShopsFromSearch(search: $search) {
            value: id
            label: name
            isDisabled: umbrellaShop
        }
    }
`;

export const GET_SHOPS_FOR_OPTIONS_BY_IDS = gql`
    query getShopsByIds($shopIds: [String]!) {
        getShopsByIds(shopIds: $shopIds) {
            value: id
            label: name
        }
    }
`;

export const GET_SHOP_ACTIVE_HOURS = gql`
    query getShopById($shopId: String!) {
        getShopById(shopId: $shopId) {
            activeHours {
                ... on ActiveHour {
                    ...activeHourResults
                }
            }
            settings {
                cateringSettings {
                    noPickupHours
                }
            }
        }
        __typename
    }
    ${ACTIVE_HOUR_ATTRIBUTES}
`;

export const GET_SHOPS_BY_COMPANY_IDS_FOR_SELECT = gql`
    query getAllShopsByCompanyIdsForFortnox($companyIds: [String]) {
        getAllShopsByCompanyIdsForFortnox(companyIds: $companyIds) {
            value: id
            label: name
            authentication: settings {
                thirdPartyAccountingSettings {
                    accountAuthenticationId
                }
            }
            companyId
        }
    }
`;

export const GET_ALL_SHOPS_BY_COMPANY_IDS = gql`
    query getAllShopsByCompanyIds($companyIds: [String!]) {
        getAllShopsByCompanyIds(companyIds: $companyIds) {
            id
            name
            umbrellaShop
            companyId
            thirdPartyDeliveryServices {
                thirdPartyDeliveryType
            }
        }
    }
`;

export const GET_SHOPS_RELATED_TO_UMBRELLA_COMPANY = gql`
    query getUmbrellaCompanyShops($companyId: String!) {
        getUmbrellaCompanyShops(companyId: $companyId) {
            id
            name
            companyId
            umbrellaShop
            thirdPartyDeliveryServices {
                thirdPartyDeliveryType
            }
        }
    }
`;

export const VALIDATE_UNLOCK_ADMIN_CODE = gql`
    query validateUnlockAdminCode($shopId: String!, $code: String!) {
        validateUnlockAdminCode(shopId: $shopId, code: $code)
    }
`;

export const GET_UMBRELLA_COMPANY_SHOPS = gql`
    query getUmbrellaCompanyShops($companyId: String!) {
        getUmbrellaCompanyShops(companyId: $companyId) {
            ... on Shop {
                ...shopResults
            }
        }
    }
    ${SHOP_ATTRIBUTES}
`;

export const GET_COMPANY_SHOPS_IMAGE_URLS = gql`
    query getCompanyShops($companyId: String!) {
        getCompanyShops(companyId: $companyId) {
            imageUrl
        }
    }
`;
